<template>
  <label :class="{ isValid: isValid }">
    <span v-if="label">
      {{this.label}}<span v-if="required">*</span>
    </span>
    <div v-if="maxLength"
         class="maxLength">
      Maximum {{maxLength}} Characters
    </div>
    <input v-if="type.toUpperCase() != 'TEXTAREA'"
           :type="type"
           :name="name"
           v-model="content"
           :placeholder="placeholder"
           :disabled="disabled"
           :maxLength="maxLength"
           @change="onChange"
           @input="handleInput"
           :required="required" />
    <textarea v-else
           type="text"
           :name="name"
           v-model="content"
           :placeholder="placeholder"
           :disabled="disabled"
           @change="onChange"
           @input="handleInput"
           :required="required" />
  </label>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: String,
    placeholder: String,
    disabled: Boolean,
    required: Boolean,
    validationFunction: {
      type: Function,
      default: () => true,
    },
    maxLength: Number,
  },
  data() {
    return {
      isValid: true,
      content: this.value || '',
    };
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
  },
  methods: {
    onChange(event) {
      this.isValid = event.target.checkValidity() && this.validationFunction(this.content);
    },
    handleInput() {
      this.$emit('input', this.content);
    },
    requiredHascontent() {
      const hascontent = this.content !== undefined && this.content !== null && this.content !== '';

      return !this.required || hascontent;
    },
  },
  created() {
    this.content = this.value;
  },
};
</script>

<style lang="scss" scoped>
  label {
    display: inline-block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnWarn;

    &.isValid {
      border: 1px solid $LincolnGray;
    }

    span {
      display: inline-block;
      font-size: 75%;
      width: 20%;
      padding: 0 1em;

      span {
        color: $LincolnWarn;
      }
    }

    input {
      border: none;
      display: inline-block;
      width: 80%;
      line-height: 3em;
      padding: 0 15px;
    }

    textarea {
      border: none;
      font-family: "Proxima Nova Regular", sans-serif;
      display: inline-block;
      width: 100%;
    }
  }
</style>
