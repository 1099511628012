var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { class: { isValid: _vm.isValid } }, [
    _vm.label
      ? _c("span", [
          _vm._v("\n    " + _vm._s(this.label)),
          _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        ])
      : _vm._e(),
    _vm.maxLength
      ? _c("div", { staticClass: "maxLength" }, [
          _vm._v("\n    Maximum " + _vm._s(_vm.maxLength) + " Characters\n  "),
        ])
      : _vm._e(),
    _vm.type === "checkbox" && _vm.type.toUpperCase() != "TEXTAREA"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          attrs: {
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            maxLength: _vm.maxLength,
            required: _vm.required,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.content)
              ? _vm._i(_vm.content, null) > -1
              : _vm.content,
          },
          on: {
            change: [
              function ($event) {
                var $$a = _vm.content,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.content = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.content = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.content = $$c
                }
              },
              _vm.onChange,
            ],
            input: _vm.handleInput,
          },
        })
      : _vm.type === "radio" && _vm.type.toUpperCase() != "TEXTAREA"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          attrs: {
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            maxLength: _vm.maxLength,
            required: _vm.required,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.content, null) },
          on: {
            change: [
              function ($event) {
                _vm.content = null
              },
              _vm.onChange,
            ],
            input: _vm.handleInput,
          },
        })
      : _vm.type.toUpperCase() != "TEXTAREA"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          attrs: {
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            maxLength: _vm.maxLength,
            required: _vm.required,
            type: _vm.type,
          },
          domProps: { value: _vm.content },
          on: {
            change: _vm.onChange,
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.content = $event.target.value
              },
              _vm.handleInput,
            ],
          },
        })
      : _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          attrs: {
            type: "text",
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            required: _vm.required,
          },
          domProps: { value: _vm.content },
          on: {
            change: _vm.onChange,
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.content = $event.target.value
              },
              _vm.handleInput,
            ],
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }